
header {
  display: none;
}
@media only screen and (max-width:640px) {
  header {
    display: block;
    position: fixed;
    top:0;
    z-index: 10;
    width: 100%;
    background-color: #fff;
    height: 54px;
    padding: 18px 28px;
    box-sizing: border-box;
    border-bottom: 1px solid #f4f4f4;
  }

}
