
:root {
  --color-background-primary: #000000;
  --color-bg-primary: #b1b1b1;
  --color-text-primary: #000000;
  --color-component-primary: #000000;
}

@charset "UTF-8";
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.8/dist/web/static/pretendard.css");
/* reset */
html, body{height:100%; width: 100%; font-size:10px; letter-spacing:-0.5px; color:#000;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,textarea,p,blockquote,th,td,input,select,textarea,button{margin:0;padding:0}
fieldset,img{border:0 none}
dl,ul,ol,menu,li{list-style:none}
blockquote, q{quotes:none}
input,select,textarea,button{vertical-align:middle}
button{border:0 none;background-color:transparent;cursor:pointer}
body{-webkit-text-size-adjust:none} /* 뷰표트 변환시 폰트크기 자동확대 방지 */
table{border-collapse:collapse;border-spacing:0}
body,th,td,input,select,textarea,button{font-size:14px;line-height:1.5;font-family:'Noto Sans KR', sans-serif;color:#000}
input[type='text'],input[type='password'],input[type='submit'],input[type='search']{-webkit-appearance:none;}
input:checked[type='checkbox']{background-color:#666;-webkit-appearance:checkbox}
button,input[type='button'],input[type='submit'],input[type='reset'],input[type='file']{-webkit-appearance:button;border-radius:0}
button{outline:none}
input[type='search']::-webkit-search-cancel-button{-webkit-appearance:none}
a{color:#242424;text-decoration:none}
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal}
em,strong{font-weight:normal;font-style:normal}
small{font-size:initial}
iframe{border:0 none;}

.content_wrap {min-height: 100vh; display: flex; flex-direction: column; width: 100%;}
.touchflow-scrollbar{background:rgba(0,0,0,0.1); transition:300ms; position:absolute; bottom:0; right:0; overflow:hidden;}
.touchflow-scrollbar div{background:rgba(0,0,0,0.3); position:absolute; top:0; left:0;}
.ir_pm{display:block;overflow:hidden;font-size:0;line-height:0;text-indent:-9999px}
.screen_out{overflow:hidden;position:absolute;width:0;height:0;line-height:0;text-indent:-9999px}
.screen_hide{font-size:0;line-height:0;visibility:hidden;margin:0 !important} /* 테이블내 caption에 사용 */
.scroll_y{overflow-y:scroll;height:100%;-ms-overflow-style:none;}
.scroll_y::-webkit-scrollbar{display:none;}
.icon_g{display:inline-block;float:none;height:auto;font-size:1px;line-height:1px;background-repeat:no-repeat;background-size:100% auto;color:transparent;text-indent:-9999px;vertical-align:top;}

.en{font-family:'Roboto-Regular', sans-serif}
.show{display:block !important}
.hide{display:none !important}
.show_pc{display:block}
.show_m{display:none}

/* input */
input{height:48px;padding:0 10px;border-radius:4px;border:1px solid #eaeaea;background-color:#fff;box-sizing:border-box;font-size:14px;line-height:46px;font-family:'Noto Sans KR', sans-serif;outline:none;color:#424242;}
input::placeholder{color:#9e9e9e;}
input.active, input:hover{border-color:var(--color-bg-primary);}
input.success{border-color:#5db182;background-color:#fafafa}
input.disabled{background-color:#f4f4f4;cursor:auto;pointer-events:none;color:#9e9e9e}
input.error{border-color:#ec6240;background-color:#fff;}
.wrap_info input + input{margin-top:8px;}

.inp_txt{display:block;width:100%;}
.inp_txt_btn{position:relative;padding-right:102px;}
.inp_txt_btn input{padding-right:60px;}
.inp_txt_btn span{position:absolute;top:0;right:114px;font-weight:700;font-size:14px;line-height:48px;color:var(--color-background-primary)}
.inp_txt_btn button{position:absolute;top:0;right:0;width:96px;height:48px;border-radius:4px;border:1px solid #eaeaea;background-color:#fff;font-size:16px;line-height:48px;color:#424242;}
.inp_txt_btn button.off{border-color:#e8e8e8;background-color:#f4f4f4;color:#bdbdbd;cursor:default;}
.inp_txt_btn button.on{}

.chk_ico{display:inline-block;position:relative;line-height:20px;vertical-align:top;}
.chk_ico + .chk_ico{margin-left:20px;}
.chk_ico input{overflow:hidden;position:absolute;width:1px;height:1px;margin:0;padding:0;clip:rect(0, 0, 0, 0);border:0 none;opacity:0.01}
.chk_ico label{display:inline-block;vertical-align:top;}
.chk_ico label:before{content:"";display:inline-block;width:20px;height:20px;background-position:50% 50%;background-repeat:no-repeat;background-size:20px 20px;vertical-align:top;}

.wrap_buttom{overflow:hidden;margin-top:40px}
.wrap_buttom .btn_g{display:inline-block;min-width:120px;padding:0 36px;border-radius:8px;border:1px solid #e8e8e8;font-weight:400;font-size:14px;line-height:42px;background-color:#fff;color:#000;vertical-align:top;text-align:center;box-sizing:border-box;}
.wrap_buttom .btn_g+.btn_g{margin-left:8px}
.wrap_buttom .btn_g.on{border-color:var(--color-background-primary);background-color:var(--color-background-primary);color:#fff;}
.wrap_buttom .btn_comm{float:left;width:calc(50% - 4px);height:48px;border-radius:4px;font-weight:500;font-size:16px;line-height:48px;background-color:#d1d5d9;color:#808991;}
.wrap_buttom .btn_comm+.btn_comm{margin-left: 8px}
.wrap_buttom .btn_comm:only-of-type{display:block;width:100%;}
.wrap_buttom .btn_comm.active{background-color:var(--color-background-primary);color:#fff;}
.wrap_buttom .btn_comm.active:hover{background-color:var(--color-text-primary);}

/* login */
.join_guide{overflow:hidden;margin-top:36px;color:#424242;}
.join_guide .txt_g{display:inline-block;font-size:14px;line-height:24px;vertical-align:top;}
.join_guide .btn_line{float:right;width:108px;height:48px;padding:0;border-radius:4px;border:1px solid #eaeaea;font-size:16px;line-height:47px;text-align:center;}

.t_ddd{display:inline-block;width:1px;height:13px;margin:4px 12px 0;background-color:#eaeaea;vertical-align:top;}
.login_box{overflow:visible;clear:both;*zoom:1}
.login_box:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''}
.login_box .box_img{float:left;width:488px;height:568px;background:url(https://s3.ap-northeast-2.amazonaws.com/sp.project/sparkplus/homepage/image/wp-content/uploads/sparkone/login_img.jpg) 50% 50% no-repeat;background-size:cover;}
.login_box .box_img strong{display:block;padding:442px 30px 30px;font-weight:700;font-size:36px;line-height:40px;text-align:right;letter-spacing:-0.5px;color:#fff;}
.login_box .box_cont{float:right;width:508px;height:568px;padding:80px 82px;border:1px solid #e8e8e8;border-left:0 none;box-sizing:border-box;}
.login_box .box_cont .title_g{display:block;margin-bottom:34px;font-weight:700;font-size:24px;line-height:34px;letter-spacing:-0.5px;text-align:center;}
.login_box .cell_g{margin-top:14px;}

.log_keep.chk_ico input[type="checkbox"] + label{font-size:14px;line-height:20px;color:#737373;}
.log_keep.chk_ico input[type="checkbox"] + label:before{margin-right:6px;background:none;border-radius:4px;border:1px solid #eaeaea;box-sizing:border-box;}
.log_keep.chk_ico input[type="checkbox"] + label:hover:before{border-color:var(--color-background-primary)}
.log_keep.chk_ico input[type="checkbox"]:checked + label:before{border:0 none;background:url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='20' height='20' rx='4' fill='%23000000'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 6.85 9.55 14 5 10.328l.883-1.092L9.307 12l4.575-6L15 6.85Z' fill='%23fff'/%3e%3c/svg%3e") 50% 50% no-repeat;background-size:20px auto}
.log_find{float:right;text-align:right;}
.log_find a{display:inline-block;font-weight:400;font-size:14px;line-height:20px;color:#424242;}

.tab_cont{display:none}
.tab_cont.on{display:block;}
.join_box .title_g{display:block;margin-bottom:50px;font-weight:700;font-size:36px;line-height:48px;letter-spacing:-0.5px;text-align:center;color:#000;}
.join_box .tab_comm{overflow:hidden}
.join_box .tab_comm .tab_btn{cursor:pointer; float:left;width:50%;height:56px;font-weight:700;font-size:14px;line-height:56px;box-shadow:inset 0 0 0 1px #e8e8e8;color:#9e9e9e;text-align:center;}
.join_box .tab_comm .tab_btn.on{background-color:#454545;color:#fff;}
.cell_frm{overflow:hidden;position:relative;padding-left:145px;}
.cell_frm .lab_g{position:absolute;top:0;left:0;font-weight:500;font-size:16px;line-height:48px;color:#616161;}
.cell_frm + .cell_frm{margin-top:8px;}

.info_box{position:relative;margin-bottom:40px;padding:17px 13px 15px 40px;border-radius:4px;border:1px solid #cee8da;font-size:14px;line-height:22px;background-color:#f7fbf9;color:#5db182;}
.info_box:before{content:"";position:absolute;top:13px;left:13px;width:24px;height:24px;background:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='12' cy='12' r='9' fill='%235DB182'/%3e%3cpath d='M11.25 8c0-.41.34-.75.75-.75s.75.34.75.75v5c0 .41-.34.75-.75.75s-.75-.34-.75-.75V8Zm1.67 8.38c-.05.13-.12.23-.21.33-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21c-.09-.1-.16-.2-.21-.33A.995.995 0 0 1 11 16c0-.13.03-.26.08-.38s.12-.23.21-.33c.1-.09.21-.16.33-.21a1 1 0 0 1 .76 0c.12.05.23.12.33.21.09.1.16.21.21.33.05.12.08.25.08.38s-.03.26-.08.38Z' fill='%23fff'/%3e%3c/svg%3e") 50% 50% no-repeat;background-size:100% auto}
.info_box.warn{border:1px solid #f9d0c6;background-color:#fef7f6;color:#ec6240;}
.info_box.warn:before{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m20.133 15.267-5.333-9.6c-.717-1.292-1.708-2-2.8-2-1.092 0-2.083.708-2.8 2l-5.333 9.6c-.675 1.225-.75 2.4-.209 3.325.542.925 1.609 1.433 3.009 1.433h10.666c1.4 0 2.467-.508 3.009-1.433.541-.925.466-2.109-.209-3.325zM11.375 9.5A.63.63 0 0 1 12 8.875a.63.63 0 0 1 .625.625v4.167a.63.63 0 0 1-.625.625.63.63 0 0 1-.625-.625V9.5zm1.217 7.258-.125.1a.626.626 0 0 1-.15.075.502.502 0 0 1-.159.05c-.05.009-.108.017-.158.017s-.108-.008-.167-.017a.529.529 0 0 1-.15-.05.626.626 0 0 1-.15-.075l-.125-.1a.876.876 0 0 1-.241-.591c0-.217.091-.434.241-.592l.125-.1c.05-.033.1-.058.15-.075a.52.52 0 0 1 .15-.05.713.713 0 0 1 .325 0 .502.502 0 0 1 .159.05c.05.017.1.042.15.075l.125.1c.15.158.241.375.241.592a.876.876 0 0 1-.241.591z' fill='%23EC6240'/%3e%3c/svg%3e");}
.info_box + .inp_txt{margin-top:24px;}

.tbl_cell{display:table;height:calc(100vh - 200px)}
.tbl_cell .cell_tbl{display:table-cell;padding:0;border:0 none;vertical-align:middle}

.wrap_info{position:relative;min-height:100%;margin:0 auto;padding:0;-webkit-box-sizing:border-box;box-sizing:border-box;}
.wrap_info .cell_tbl{display:table-cell;padding:0;border:0 none;vertical-align:middle}
.wrap_info .link_back{display:inline-block;margin-top:20px;font-size:16px;line-height:23px;color:#737373;vertical-align:top;}
.wrap_info .title_sparkplus{display:block;float:none;}
.wrap_info .title_sparkplus a{width:185px;height:20px;margin:0 auto 50px;}
.wrap_info .txt_info{display:inline-block;font-weight:400;font-size:14px;line-height:24px;color:#737373;vertical-align:top;}
.wrap_info .txt_info:before, .wrap_info .txt_info:after{content:"";display:inline-block;width:24px;height:24px;vertical-align:top;background-repeat:no-repeat;background-size:100% auto;}

.wrap_info.login{width:996px;}
.wrap_info.login > .info_box{margin-bottom:24px;}
.wrap_info.login .wrap_bottom{display:block;margin-top:40px;}

.wrap_info.join{width:488px;padding-top:156px; }
.wrap_info.join .txt_g{padding:32px 0;font-size:16px;line-height:26px;text-align:center;color:#000;}
.wrap_info.join .txt_g2{margin-bottom:40px;font-size:14px;line-height:24px;text-align:center;color:#424242;}
.txt_greet{margin-top:40px;font-weight:400;font-size:18px;line-height:28px;color:#000;text-align:center;}
.btn_confirm{display:block;width:223px;height:48px;margin:32px auto 0;border-radius:4px;border:1px solid #eaeaea;font-size:16px;line-height:48px;color:#424242}

.cell_helf{margin-top:8px;text-align:right;}
.cell_helf .txt_info:after{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='12' cy='12' r='9' fill='%239a9a9a'/%3e%3cpath d='M10 10.25c0-.445.117-.88.337-1.25.22-.37.532-.658.898-.829.365-.17.767-.215 1.155-.128s.744.301 1.024.616.47.716.548 1.152c.077.437.037.889-.114 1.3a2.214 2.214 0 0 1-.737 1.01c-.329.247-.715.379-1.111.379V14' stroke='%23fff' stroke-width='1.4' stroke-linecap='round'/%3e%3cpath d='M12 16v.4' stroke='%23fff' stroke-width='1.6' stroke-linecap='round'/%3e%3c/svg%3e")}
.cell_prev{margin-top:32px;text-align:center;}
.cell_prev .txt_info:before{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9.8 7 5 12m0 0 4.8 5M5 12h14' stroke='%23737373' stroke-width='1.6' stroke-linejoin='round'/%3e%3c/svg%3e");}

.step_flow{overflow:hidden;position:relative;margin-bottom:48px;font-size:1px;text-align:center;}
.step_flow:before{content:"";position:absolute;top:30px;left:50%;width:280px;margin-left:-140px;border:1px dashed #eaeaea;}
.step_flow li{display:inline-block;overflow:hidden;position:relative;text-align:center;color:#bfbfbf;vertical-align:top;color:#bfbfbf}
.step_flow li+li{margin-left:54px;}
.step_flow .num_step{display:block;overflow:hidden;position:relative;width:60px;height:60px;margin:0 auto;border-radius:60px;background-color:#f4f4f4;font-weight:700;font-size:22px;line-height:60px;font-family:"Roboto",sans-serif;}
.step_flow .txt_step{display:block;margin-top:8px;font-weight:400;font-size:16px;line-height:26px;}
.step_flow li.on{color:#4d4d4d}
.step_flow li.on .num_step{background-color:#4d4d4d;color:#fff;}
.step_flow li.on .num_step:after{content:"";position:absolute;top:0;left:0;width:100%;height:100%;background-color:#4d4d4d;background-position:50% 50%;background-repeat:no-repeat;background-size:24px 24px;}
.step_flow .step_01 .num_step:after{background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23a)'%3e%3cmask id='b' fill='%23fff'%3e%3crect x='3' y='1' width='18' height='22' rx='.6'/%3e%3c/mask%3e%3crect x='3' y='1' width='18' height='22' rx='.6' stroke='%23fff' stroke-width='3.2' mask='url(%23b)'/%3e%3cpath d='M16 7H8M16 10H8M12 13H8' stroke='%23fff' stroke-width='1.6' stroke-linecap='round'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='%23fff' d='M0 0h24v24H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");}
.step_flow .step_02 .num_step:after{background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23a)' stroke='%23fff' stroke-width='1.6'%3e%3cpath d='M21 22H3' stroke-linecap='round'/%3e%3cpath d='M16.535 8.536 13 5M4.392 13.79 15.436 2.745l3.819 3.819L8.21 17.608l-3.66-.158-.159-3.66Z'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='%23fff' d='M0 0h24v24H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");}
.step_flow .step_03 .num_step:after{background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cg clip-path='url(%23a)' stroke='%23fff' stroke-width='1.6' stroke-linecap='round'%3e%3cpath d='M10.5 6H3v15h15v-7.5'/%3e%3cpath d='m7 10.778 4.51 4.51L19.29 7.51'/%3e%3c/g%3e%3cdefs%3e%3cclipPath id='a'%3e%3cpath fill='%23fff' d='M0 0h24v24H0z'/%3e%3c/clipPath%3e%3c/defs%3e%3c/svg%3e");}

.wrap_info.done{padding-top:220px;text-align:center;}
.wrap_info.done .title_cont:before{content:"";display:block;width:50px;height:50px;margin:0 auto 16px;background:url("data:image/svg+xml;charset=UTF-8,%3csvg width='50' height='50' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M44.213 5.787C41.025 2.598 35 0 25 0 15 0 8.984 2.598 5.787 5.787 2.598 8.975 0 15 0 25c0 10 2.598 16.016 5.787 19.213C8.975 47.402 15 50 25 50c10 0 16.016-2.598 19.213-5.787C47.402 41.025 50 35 50 25c0-10-2.598-16.016-5.787-19.213z' fill='%23eaeaea'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='m13.272 24.812 3.83-3.83 5.668 5.668L32.9 16.521l3.83 3.83-13.96 13.96-9.498-9.499z' fill='%23fff'/%3e%3cpath d='m13.272 24.812 3.83-3.83 9.523 9.524-3.855 3.805-9.498-9.499z' fill='%23fff'/%3e%3c/svg%3e") 50% 50% no-repeat;background-size:100% auto;}
.wrap_info.done .btn_success{width:180px;margin:40px auto 0}
.wrap_info.done .btn_success + div{margin-top:48px;}
.wrap_info.my_info{padding-top:120px}
.wrap_info .title_cont{margin-bottom:16px;line-height:46px;}
.wrap_info.join .title_cont{margin-bottom:50px;}
.wrap_info .tbl_comm{margin-top:32px;border-top:2px solid #9a9a9a}
.wrap_info .tbl_comm th, .wrap_info .tbl_comm td{padding:24px 0;font-size:16px;line-height:23px;}
.wrap_info .tbl_comm th{color:#737373;text-align:left;}
.wrap_info .tbl_comm td{font-weight:500;text-align:right;}

.title_join{display:block;padding:8px 0;font-weight:700;font-size:24px;line-height:34px;letter-spacing:-0.5px;}
// .cont_ac{display:none;}
.btn_ac{display:block;position:relative;padding-right:30px;border-bottom:1px solid #e8e8e8;font-size:16px;line-height:58px;color:#000;cursor:pointer;}
.btn_ac span{color:#9e9e9e}
.btn_ac:after{content:"";position:absolute;top:50%;right:8px;width:24px;height:24px;margin-top:-12px;background:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m7.131 9 6.394 6.393-1.132 1.132L6 10.13 7.131 9Z' fill='%239EA7AD'/%3e%3cpath d='M17.657 9 12 14.657l1.131 1.131 5.657-5.657L17.657 9Z' fill='%239EA7AD'/%3e%3c/svg%3e") 50% 50% no-repeat;background-size:100% auto}
.btn_ac.open:after{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m7.131 15.525 6.394-6.394L12.393 8 6 14.393l1.131 1.132Z' fill='%239EA7AD'/%3e%3cpath d='M17.657 15.525 12 9.868l1.131-1.132 5.657 5.657-1.131 1.132Z' fill='%239EA7AD'/%3e%3c/svg%3e");}
.btn_ac.open + .cont_ac{display:block;padding-bottom:8px;border-bottom:1px solid #e8e8e8}
.btn_ac.ac_none:after{display:none;}
.chk_ico input[type="radio"] + label{font-size:14px;line-height:20px;color:#737373;}
.chk_ico input[type="radio"] + label:before{margin-right:6px;background:none;border-radius:20px;border:1px solid #eaeaea;box-sizing:border-box;}
.chk_ico input[type="radio"] + label:hover:before{border-color:var(--color-background-primary)}
.chk_ico input[type="radio"]:checked + label:before{border-color:var(--color-background-primary)}
.chk_ico input[type="radio"]:checked + label:after{content:"";position: absolute;top:4px;left:4px;width:12px;height:12px;border-radius:12px;background-color:var(--color-background-primary);}
.chk_ico input[type="radio"]:checked + label span{color:#000}

.cell_chk{margin-top:40px;margin-bottom:24px;text-align:center;}

.step_join .cell_agree{border-top:1px solid #9a9a9a;border-bottom:1px solid #9a9a9a}
.step_join .cell_agree > div{margin-top:14px;padding:0 14px;}
.step_join .cell_agree .agree_all{padding:13px 0;border-bottom:1px solid #e8e8e8;font-weight:700;font-size:16px;line-height:24px;background-color:#fff;color:var(--color-text-primary)}
.step_join .cell_agree .agree_all span{font-weight:700;font-size:16px;color:var(--color-text-primary)}
.step_join .cell_agree .chk_ico{display:block;}
.step_join .cell_agree .chk_ico span{margin-left:4px;}
.step_join .cell_agree .chk_ico input[type="checkbox"] + label:before{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='.5' y='.5' width='19' height='19' rx='3.5' stroke='%23eaeaea'/%3e%3c/svg%3e");}
.step_join .cell_agree .chk_ico input[type="checkbox"]:checked + label:before{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='20' height='20' rx='4' fill='%23000000'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 6.85 9.55 14 5 10.328l.883-1.092L9.307 12l4.575-6L15 6.85Z' fill='%23fff'/%3e%3c/svg%3e");}
.step_join .cell_agree .btn_ac{position:absolute;right:0;top:0;line-height:40px;border-bottom:0 none;}
.step_join .cell_agree .btn_ac:after{right:0;}
.step_join .cell_agree .cont_ac{margin-top:4px;margin-left:26px;padding:24px 10px;border-top:1px solid #e8e8e8;font-weight:400;font-size:14px;line-height:24px;color:#737373;}
.step_join .agree_list li{position:relative;padding-left:26px;}
.step_join .agree_list .chk_ico{padding:10px 0;}
.step_join .agree_list label span{font-weight:500;font-size:14px;color:#424242;}

.tbl_list{padding-top:16px;border-top:1px solid #9a9a9a;}
.tbl_list>li{position:relative;padding-left:145px;font-size:16px;letter-spacing:-0.5px;color:#424242;}
.tbl_list .tit_g{position:absolute;top:0;left:0;width:150px;padding:7px 0;line-height:48px;color:#737373;}
.tbl_list .desc_g{padding-top:8px;padding-bottom:8px;font-weight:500;}
.tbl_list .inp_txt_btn button{top:8px;}
/* .tbl_list .txt_warn{margin-top:6px;font-weight:normal;font-size:12px;line-height:16px;color:#ec6240;} */
.txt_warn{margin-top:6px;font-weight:normal;font-size:12px;line-height:16px;color:#ec6240;}
.tbl_list + .tit_line{margin-top:64px;}
.tbl_list .lab_tit{margin-top:24px;margin-bottom:6px;font-weight:500;}
.tbl_list .inp_txt+.lab_tit{margin-top:16px;}

/* 레이어팝업 */
.btn_close{position:absolute;top:16px;right:16px;border:0 none;outline:none;background:none}
.btn_close .icon_g{width:24px;height:24px;background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='30' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.998 13.678 6.383 5.062 5.058 6.389l8.615 8.614-8.616 8.61 1.326 1.326 8.615-8.61 8.61 8.61 1.325-1.325-8.61-8.61 8.616-8.61-1.326-1.325L15 13.678Z' fill='%23242424'/%3e%3c/svg%3e");background-position:50% 50%}
.btn_close .icon_g.fff{background-image:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.293 4.293a1 1 0 1 1 1.414 1.414L13.414 12l6.293 6.293a1 1 0 0 1-1.414 1.414L12 13.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L10.586 12 4.293 5.707a1 1 0 0 1 1.414-1.414L12 10.586l6.293-6.293z' fill='%23FFF'/%3e%3c/svg%3e");}
.btn_close .icon_circle{width:40px;height:40px;background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='40' height='40' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='20' cy='20' r='16' fill='%23000' fill-opacity='.4'/%3e%3cpath d='m25 15-5 5m-5 5 5-5m0 0 5 5-10-10' stroke='%23fff' stroke-width='1.6'/%3e%3c/svg%3e");}
.layer-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  position:fixed;top:0;right:0;bottom:0;left:0;height:100vh;background-color:rgba(0,0,0,0.44);box-sizing:border-box;z-index:16}
.layer-container{
  overflow:hidden;width:640px;max-height:80vh;margin:20% auto 0;padding-top:56px;background-color:#fff;}
.layer-s-container{
  overflow:hidden;position:relative; width:340px;/* margin:27% auto 0; */border-radius:8px;background-color:#fff;text-align:center;}
.layer-header{position:absolute;top:0;left:0;width:100%;height:56px;padding:0 20px;font-weight:500;font-size:18px;line-height:56px;background-color:#e7ecf2;box-sizing:border-box;z-index:1;}
.layer-header .title{display:block;font-weight:500;font-size:18px}
.layer-body{position:relative;height:100%;padding:34px;box-sizing:border-box;}
.layer-body .title_layer{display:block;margin-bottom:18px;font-weight:700;font-size:18px;line-height:26px;color:#000;}
.layer-body p{font-weight:400;font-size:16px;line-height:26px;color:#424242;}
.layer-body strong + p{font-size:14px;line-height:24px;}
.layer-body .wrap_buttom{margin-top:24px;}

@media only screen and (max-width:640px) {
  .content_wrap {
    padding-top: 54px;
  }
  .show_pc{display:none}
  .show_m{display:block}
  .wrap_buttom .btn_comm{height:56px;font-size:16px;line-height:56px;}
  .login_box, .login_box .box_cont{width:100%;}
  .login_box .box_img{display:none;}
  .login_box .box_cont{float:none;height:auto;padding:60px 0 210px;border:0 none;}

  .cell_frm{padding-left:0}
  .cell_frm .lab_g{display:block;position:relative;padding-bottom:4px;line-height:23px;}
  .cell_frm+.cell_frm{margin-top:32px;}
  .join_box .title_g{margin-bottom:32px;font-size:24px;line-height:34px;}

  .wrap_info{width:100% !important;padding:10px 16px 119px;box-sizing:border-box;}
  .wrap_info.login{padding:0 16px;}
  .wrap_info.my_info{padding-top:10px;}
  .wrap_info.my_info .title_cont{margin-bottom:32px;}

  .wrap_info.join{padding-top:60px;}
  .wrap_info.done{padding-top:150px;}

  .step_flow{margin-bottom:32px;}
  .step_flow:before{top:12px;width:80px;margin-left:-40px;}
  .step_flow .num_step{width:24px;height:24px;font-size:15px;line-height:25px;}
  .step_flow li+li{margin-left:16px;}
  .step_flow li.on .num_step:after, .step_flow .txt_step{display:none;}
  .done .step_flow:before{top:7px;}
  .done .step_flow .num_step{width:16px;height:16px;font-size:1px !important;;color:transparent !important;}
  .done .step_flow li+li{margin-left:26px;}

  .title_join{font-size:18px;line-height:26px}
  .step_join .agree_list li{padding-left:0}
  .step_join .cell_agree .cont_ac{margin-left:0;}
  .step_join .btn_down{display:block;width:200px;height:48px;margin:40px auto 0;border-radius:4px;font-weight:500;font-size:16px;line-height:48px;background-color:#9a9a9a;color:#fff;}
  .step_join .btn_down:after{content:"";display:inline-block;width:24px;height:48px;margin-left:8px;vertical-align:top;background:url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='%23fff' d='M10.667 2.667H12.8v12h-2.133zM20 18.667V20.8H4v-2.133z'/%3e%3cpath fill='%23fff' d='m13.504 14.933-1.508 1.508-6.6-6.6 1.509-1.507z'/%3e%3cpath fill='%23fff' d='m10.528 14.9 1.509 1.508 6.599-6.6L17.128 8.3z'/%3e%3c/svg%3e") 50% 50% no-repeat;background-size:100% auto;}

  .tbl_list{padding-top:36px;}
  .tbl_list>li{padding-left:0;margin-top:20px;}
  .tbl_list>li:first-child{margin-top:0}
  .tbl_list .tit_g{position:relative;top:auto;left:auto;padding:0;font-size:14px;line-height:20px;}
  .tbl_list .desc_g{padding-bottom:0}
}

.loading {
  width:100%; height:100vh;
  top: 0;
  z-index: 9;
  position:fixed;
  background: #0001;
}
@keyframes loading {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(2px);
  }
  100% {
    backdrop-filter: blur(0px);
  }
}

.circle {
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin : auto;
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  border-top-color: var(--color-component-primary);
  animation: spin 1s ease-in-out infinite;
  animation: spin .5s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}
